import * as React from "react"
import LayoutPDF from '../components/layout/LayoutPDF';

export default function IdentityCard() {
	return (
		<LayoutPDF>
			<div>Type de document: Carte d'identité Française</div>
			<div>Cette carte d'identité a été générée par Veridiq</div>
			<div className="bg-green-500">
				<img className="object-contain h-48 w-full" src={'/identity_photo.jpg'} />
			</div>

		</LayoutPDF>
	)
}

// https://pixabay.com/fr/photos/femme-portrait-mod%C3%A8le-sourire-657753/

// https://pixabay.com/fr/photos/femme-fille-portrait-beaut%C3%A9-3584435/