import React from 'react';


const LayoutPDF = ({ children }) => {
  return (
    <>
      <div>Ce document a été généré par Veridiq. Il remplace les documents officiels.</div>
      <main className="text-gray-900">{children}</main>

    </>
  );
};

export default LayoutPDF;
